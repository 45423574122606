import React from "react";
import { LocalizedRouter as Router } from "gatsby-theme-i18n";
import Navbar from "/src/containers/navbar";
import BacThemeProvider from "/src/providers/bac-theme-provider";
import config from "/src/config.js";
import SEO from "/src/components/seo";
import RegionAssetsContainer from "/src/containers/region-assets";

const RegionAssetsPage = () => {
  return (
    <>
      <SEO
        title={`${config.CLIENT} - Region Assets`}
        description={config.CLIENT_DESCRIPTION}
        image={encodeURI(config.LOGO_DARK) || ""}
      />
      <BacThemeProvider>
        <Navbar />
        <Router basePath="/">
          <RegionAssetsContainer path="region-assets/:regionId" />
        </Router>
      </BacThemeProvider>
    </>
  );
};

export default RegionAssetsPage;
